/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, SimpleGrid } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the Card Info 4 Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement} CardInfo4 Card component
 */

const CardInfo4 = ({ sectionContent, region, lang }) => {
  const bgColors = Styles.bgColors;

  return (
    <SimpleGrid
      // columns='2'
      columns={{
        sm: '1',
        md: '2',
        lg: '2',
        xl: sectionContent?.cards.length < 3 ? 2 : '4',
      }}
      m="auto"
      maxW={{
        xl: sectionContent?.cards.length < 3 ? '585px' : '1170px',
        lg: '585px',
        md: '570px',
        sm: '270px',
        base: '270px',
      }}
      textAlign="left"
      spacing={{ xl: '30px', lg: '30px', md: '31px', sm: '31px' }}
    >
      {sectionContent?.cards?.map((secCon, index) => {
        return (
          <Box w="270px" position="relative" key={nanoid()}>
            <Box
              w={{
                xl: '243.62px',
                lg: '243.62px',
                md: '242.72px',
                sm: '242.72px',
                base: '242.72px',
              }}
              h={{
                xl: '187.07px',
                lg: '187.07px',
                md: '186.38px',
                sm: '186.38px',
                base: '186.38px',
              }}
              objectFit="contain"
              transform="rotate(-178.76deg)"
              position="absolute"
              left="13.5px"
              background={bgColors[index % bgColors.length]}
              outline="1px solid transparent"
              zIndex="-1"
              top="-10px"
            ></Box>
            <Box>
              <NextLink
                href={refineUrlDomain(secCon.buttonLink, region, lang)}
                passHref
                legacyBehavior>
                <Link>
                  <LazyLoadImageComponent
                    src={secCon.thumbnail?.url}
                    title={secCon.thumbnail.title}
                    alt={secCon.thumbnail.alt}
                    width="270px"
                    height="165.38px"
                  />
                </Link>
              </NextLink>
            </Box>
            <Box
              display={'grid'}
              ml="13.5px"
              mt="32.52px"
              w={{
                xl: '243.62px',
                base: '242.72px',
              }}
            >
              <Box
                as="span"
                fontFamily="FedraSerifAStdBook"
                fontSize="24px"
                color="#28231E"
              >
                {secCon.title}
              </Box>
              <Box
                fontFamily="FedraSansStd-book"
                fontSize="16px"
                color="#676055"
                mt="16px"
                mb="16px"
                lineHeight={'26.02px'}
              >
                <StructuredTextParser
                    str={render(secCon.description)}
                    region={region}
                    lang={lang}
                    mt="0"
                  />
              </Box>
              {secCon.buttonLink != "" && 
              <Box mt="20px">
                <NextLink
                  href={refineUrlDomain(secCon.buttonLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    bgColor="transparent"
                    color="#cf4520"
                    mr={{ md: '20px' }}
                    padding="5px 12px"
                    border="1px solid #cf4520"
                    borderRadius="5px"
                    display="inline-block"
                    mb={['44px', '40px', '36px', '24px', '20px']}
                    fontFamily="FedraSansStd-medium"
                    fontSize="13px"
                    _hover={{
                      color: '#fff',
                      bgColor: '#cf4520',
                      textDecoration: 'none',
                    }}
                    _focus={{
                      color: '#fff',
                      bgColor: '#cf4520',
                      textDecoration: 'none',
                    }}
                    _active={{
                      color: '#fff',
                      bgColor: '#cf4520',
                      textDecoration: 'none',
                    }}
                    textTransform="capitalize"
                  >
                    {secCon.buttonText}
                  </Link>
                </NextLink>
              </Box>
            }
            </Box>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export default CardInfo4;
